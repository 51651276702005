<template>
    <div>
        <TerminalUpdate type="batchUpdate"></TerminalUpdate>
    </div>
</template>

<script>
    import TerminalUpdate from '@/components/terminalUpdate/Index';
    export default {
        name:'terminalBatchUpdate',
        components:{
            TerminalUpdate
        },
        data() {
            return {
            }
        },
        mounted() {
        },
        
    };
</script>

<style scoped>

</style>
